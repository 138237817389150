// Themes

:root,
.rs-theme-light {
  @import 'color-modes/light';
}

& when (@enable-dark-mode = true) {
  .rs-theme-dark {
    @import 'color-modes/dark';
  }
}

// TODO consider adding flag for high-contrast theme
.rs-theme-high-contrast {
  @import 'color-modes/high-contrast';
}
