// Rules that take effect in dark mode.
.dark-mode(@rules) {
  & when (@enable-dark-mode = true) {
    .rs-theme-dark & {
      @rules();
    }
  }
}

.high-contrast-mode(@rules) {
  .rs-theme-high-contrast & {
    @rules();
  }
}
