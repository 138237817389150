@import '../../styles/common';
@import '../../styles/mixins/listbox';
@import '../../Button/styles/mixin';
@import '../../Picker/styles/mixin';
@import '../../Stack/styles/index';
@import 'mixin';

// DatePicker Picker
// ----------------------

// Change the <caret/> & <clean/> vertical position make it align middle.
.rs-picker-date,
.rs-picker-daterange {
  &.rs-picker-default {
    .rs-picker-toggle-caret,
    .rs-picker-toggle-clean {
      top: @padding-y - @picker-default-toggle-border-width;
    }
  }
}

// Toolbar
.rs-picker-toolbar {
  padding: @calendar-picker-padding;
  border-top: 1px solid @calendar-toolbar-border-color;
}

// Picker date
.rs-picker-date {
  &-inline {
    height: 299px;

    .rs-calendar {
      height: 286px;
    }
  }
}

// Predefined Ranges
.rs-picker-date-predefined {
  height: 325px;
  border-right: 1px solid var(--rs-border-primary);
  padding: 4px 0;

  .rs-btn {
    display: block;
  }
}

// Calendar in DatePicker popup
.rs-picker-menu {
  @calendar-width: 264px; // This is a fixed value per design.

  @calendar-padding-x: 15px;
  // The padding used when show week numbers.
  @calendar-padding-x-condensed: 12px;

  .rs-picker-toolbar {
    max-width: 100%;

    &-ranges {
      max-width: 400px;
    }
  }

  .rs-calendar {
    min-width: @calendar-width;
    // To make sure <Calendar/> horizontal align at center when toolbar  width is so long.
    display: block;
    margin: 0 auto;

    &-show-week-numbers {
      @calendar-width: 278px; // This is a fixed value per design.

      min-width: @calendar-width;

      & .rs-calendar-body {
        padding-left: @calendar-padding-x-condensed;
        padding-right: @calendar-padding-x-condensed;
      }
    }

    &-header {
      width: 100%;
    }

    &-body {
      padding-left: @calendar-padding-x;
      padding-right: @calendar-padding-x;
    }

    &-table {
      width: unset;
    }

    .rs-calendar-month-dropdown-cell-content,
    .rs-calendar-table-cell-content {
      width: @calendar-in-menu-content-side-length;
      height: @calendar-in-menu-content-side-length;
    }

    .rs-calendar-month-dropdown-cell-content:hover {
      .listbox-option-active();
    }

    .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content:hover {
      .calendar-cell-selected-hover();
    }

    .rs-calendar-table-header-row .rs-calendar-table-cell-content {
      height: 24px;
      padding-top: 0;
    }

    .rs-calendar-table-cell-content {
      padding-left: 0;
      padding-right: 0;
      display: inline-block;
    }

    // There's a small gap between cell and cell-content (cell-content smaller than cell)
    // So don't apply hover style directly on cell-content
    .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
      // borrowed from listbox option
      .listbox-option-active();
    }

    .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
      .calendar-cell-selected-hover();
    }

    .rs-calendar-month-dropdown-scroll {
      height: 227px;
    }

    .rs-calendar-time-dropdown-column > ul {
      height: 214px;
      padding-bottom: 184px;
    }
  }
}
