@import '../../styles/mixins/color-modes';
// States

.button-activated(@rules) {
  &:hover,
  &:focus {
    @rules();
  }
}

.button-pressed(@rules) {
  &:active,
  &.rs-btn-active {
    @rules();
  }
}

.button-disabled(@rules) {
  &:disabled,
  &.rs-btn-disabled {
    @rules();

    .high-contrast-mode({
      opacity: 0.5;
    });
  }
}

// Sizes

// TODO: rename
.button-size(@height; @space-x; @font-size; @line-height; @icon-size) {
  @space-y: ((@height - @line-height) / 2);

  font-size: @font-size;
  line-height: @line-height;
  padding: @space-y @space-x;

  .rs-btn-ghost& {
    // Ghost button has 1px border
    padding: (@space-y - @btn-ghost-border-width) (@space-x - @btn-ghost-border-width);
  }

  .rs-btn-icon& {
    @space-y: ((@height - @icon-size) / 2);
    @space-x: @space-y;

    padding: @space-y @space-x;
    line-height: @icon-size;

    > .rs-icon {
      font-size: @icon-size;
    }
  }

  .rs-btn-icon-with-text& {
    line-height: @line-height;

    > .rs-icon {
      @space-y: ((@height - @icon-size) / 2);
      @space-x: @space-y;

      padding: @space-y @space-x;
      width: @height;
      height: @height;
    }

    &.rs-btn-icon-placement-left {
      padding: @space-y @space-x @space-y (@height + @space-x);

      > .rs-icon {
        left: 0;
        border-right: var(--rs-btn-default-border, none);
      }
    }

    &.rs-btn-icon-placement-right {
      padding: @space-y (@height + @space-x) @space-y @space-x;

      > .rs-icon {
        right: 0;
        border-left: var(--rs-btn-default-border, none);
      }
    }
  }
}

.button-size-lg() {
  .button-size(
    @height: 42px;
    @space-x: 16px;
    @font-size: 16px;
    @line-height: 22px;
    @icon-size: 20px;
  );
}

.button-size-md() {
  .button-size(
    @height: 36px;
    @space-x: 12px;
    @font-size: 14px;
    @line-height: 20px;
    @icon-size: 16px;
  );
}

.button-size-sm() {
  .button-size(
    @height: 30px;
    @space-x: 10px;
    @font-size: 14px;
    @line-height: 20px;
    @icon-size: 16px;
  );
}

.button-size-xs() {
  .button-size(
    @height: 24px;
    @space-x: 8px;
    @font-size: 12px;
    @line-height: 20px;
    @icon-size: 12px;
  );
}
