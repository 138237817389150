@import '../../styles/common';
@import '../../Button/styles/index';
@import '../../Picker/styles/index';
@import '../../DatePicker/styles/index';
@import '../../Calendar/styles/index';

// Date range Picker
// ----------------------

.rs-picker-daterange-menu {
  .rs-calendar {
    display: inline-block;
    height: 274px;
    padding-bottom: 12px;

    &:first-child {
      border-right: 1px solid var(--rs-border-primary);
    }

    &-header {
      width: 100%;
      text-align: center;
    }

    &-header-month-toolbar {
      float: none;
    }
  }

  .rs-calendar-month-dropdown {
    z-index: @zindex-date-range-picker-calendar-dropdown;

    &-list {
      width: 185px;
    }
  }

  .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar {
    max-width: @date-range-picker-calendar-default-width;

    &-ranges {
      width: 190px;
    }
  }

  // Calendar single
  .rs-picker-daterange-calendar-single {
    .rs-calendar {
      border: 0;
      display: block;
      margin: auto;
    }
  }
}

// Header
.rs-picker-daterange-header {
  padding: @date-range-picker-header-padding-vertical @picker-menu-padding;
  font-size: @date-range-picker-header-font-size;
  line-height: @date-range-picker-header-line-height;
  border-bottom: 1px solid var(--rs-border-primary);
}

// Calendar group
.rs-picker-daterange-calendar-group {
  height: 274px;
  // Make sure group wrapper can put 2 date-panels even screen width is not enough.
  min-width: 492px;
}

// Predefined Ranges
.rs-picker-daterange-predefined {
  height: 366px;
  border-right: 1px solid var(--rs-border-primary);
  padding: 4px 0;

  .rs-btn {
    display: block;
  }
}
