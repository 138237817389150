// ARIA Listboxes and Options
// used in picker menus, e.g. SelectPicker, CheckPicker, Cascader etc
//
// Common usage: (note the orders of different states)
//
// .item {
//   .listbox-option();
//
//   &:hover,
//   &:focus {
//     .listbox-option-active();
//   }
//
//   &-selected {
//     .listbox-option-selected();
//   }
//
//   &-disabled {
//     .listbox-option-disabled();
//   }
// }
@import 'color-modes';

.listbox() {
  list-style: none;
}

.listbox-option() {
  display: block;
  padding: @picker-item-content-padding-vertical @picker-item-content-padding-horizontal;
  clear: both;
  font-weight: normal;
  line-height: @line-height-base;
  color: var(--rs-text-primary);
  // FIXME-Doma
  // No need to use pointer, just use default
  cursor: pointer;
  text-decoration: none;
  width: 100%;
}

// "-active" for aria-activedescendant
.listbox-option-active(@multiselectable: false) {
  background-color: var(--rs-listbox-option-hover-bg);

  // No need to change text color in multiselectable listboxes
  // in favor of checkbox state changes
  & when (@multiselectable = false) {
    color: var(--rs-listbox-option-hover-text);
  }

  .high-contrast-mode({
    .focus-ring(slim-inset);

    color: var(--rs-listbox-option-hover-text);
    text-decoration: underline;
  });
}

.listbox-option-selected() {
  background-color: var(--rs-listbox-option-selected-bg);
  font-weight: bold;
  color: var(--rs-listbox-option-selected-text);

  .high-contrast-mode({
    text-decoration: underline;
  });
}

.listbox-options-disabled() {
  color: var(--rs-listbox-option-disabled-text);
  background: none;
  cursor: not-allowed;
}
